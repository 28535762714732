<template>
  <div class="container">
    <h1 class="title__two">Zure itzulpenak</h1>

    <loader v-if="loader"></loader>
    <div class="posts" :class="{ blur: loader }">
      <div style="overflow-x:auto;">
        <table>
          <tr>
            <th>Data</th>
            <th>Dokumentua</th>
            <th>Saila</th>
            <th>Bidaltzailea</th>
            <th>Itzulpena</th>
          </tr>

          <tr
            v-for="(item, index) in itzulpenak"
            :key="index"
            :class="{ closed: !item.acf.itxita }"
          >
            <td>{{ item.date }}</td>
            <td>
              <router-link :to="`/edit-post/${item.id}`">{{
                item.title
              }}</router-link>
              <font-awesome-icon icon="arrow-circle-right" />
            </td>

            <td>{{ item.acf.saila }}</td>
            <td>{{ item.acf.bidaltzailea }}</td>
            <td>
              <template v-if="_getLastDoc(item)">
                <a :href="_getLastDoc(item)" target="_blank">
                  Deskargatu
                  <font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
                </a>
              </template>
            </td>
          </tr>
        </table>
      </div>

      <div class="pagination">
        <template v-for="index in pageTotal" :key="index">
          <button @click="_load(index)" :class="{ active: index == page }">
            {{ index }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
// @ is an alias to /src
export default {
  name: "AllPostsUser",
  components: {
    Loader,
  },
  data() {
    return {
      loader: true,
      itzulpenak: [],
      page: null,
      pageTotal: null,
      perPage: 30,
    };
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  mounted() {
    document.title = "Erabiltzailearen itzulpenak - Itzulpengintza zerbitzua";
    this._load(1);
  },
  methods: {
    _load(i) {
      this.page = i;
      axios
        .get(
          "itzulpenak/v1/get-by-user?user=" +
            this.store.auth.ID +
            "&per_page=" +
            this.perPage +
            "&page=" +
            i
        )
        .then((res) => {
          this.itzulpenak = res.data.response;
          this.pageTotal = parseInt(res.headers["x-wp-totalpages"]);
          this.loader = false;
        });
    },
    _getLastDoc(post) {
      if (!post.acf.edukia) return null;

      let doc = null;
      post.acf.edukia.forEach((res) => {
        if (res.acf_fc_layout == "dokumentua") {
          doc = res.dokumentua.url;
        }
      });

      return doc;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.posts {
  &.blur {
    filter: blur(5px);
  }
}
</style>
