<template>
  <div class="container">
    <h1 class="title__two">Estatistikak</h1>

    <loader v-if="loader"></loader>
    <div class="statistics" :class="{ blur: loader }">
      <div style="overflow-x:auto;">
        <table v-if="dates">
          <tr>
            <th>Bezeroa</th>
            <th v-for="year in dates" :key="year">
              {{ year }}
            </th>
          </tr>

          <tr v-for="(item, index) in itzulpenak" :key="index">
            <td>{{ item.user }}</td>

            <td v-for="year in dates" :key="year">
              {{ _getCount(year, item) }}
            </td>
          </tr>
        </table>
      </div>

      <div class="pagination">
        <template v-for="index in pageTotal" :key="index">
          <button @click="_load(index)" :class="{ active: index == page }">
            {{ index }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
// @ is an alias to /src
export default {
  name: "Statistics",
  components: {
    Loader,
  },
  data() {
    return {
      loader: true,
      itzulpenak: [],
      dates: false,
      page: null,
      pageTotal: null,
      perPage: 40,
    };
  },
  mounted() {
    document.title = "Estatistikak - Itzulpengintza zerbitzua";
    this._load(1);
  },
  methods: {
    _load(i) {
      this.dates = [];
      this.page = i;
      this.loader = true;

      axios
        .get("itzulpenak/v1/statistics?per_page=" + this.perPage + "&page=" + i)
        .then((res) => {
          this.itzulpenak = res.data.response;
          res.data.response.forEach((user) => {
            user.posts.forEach((post) => {
              let year = new Date(post).getFullYear();
              if (!this.dates.includes(year)) this.dates.push(year);
            });
          });
          this.pageTotal = parseInt(res.headers["x-wp-totalpages"]);

          this.loader = false;
        });
    },
    _getCount(year, user) {
      let count = 0;
      user.posts.forEach((post) => {
        let postYear = new Date(post).getFullYear();
        if (year == postYear) count++;
      });

      return count;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.statistics {
  &.blur {
    filter: blur(5px);
  }
}
</style>
