<template>
  <div class="container">
    <h1 class="title__two">Itzulpenak</h1>

    <loader v-if="loader"></loader>
    <div class="posts" :class="{ blur: loader }">
      <div style="overflow-x:auto;">
        <table>
          <tr>
            <th>Data</th>
            <th>Dokumentua</th>
            <th>Bezeroa</th>
            <th>Saila</th>
            <th>Bidaltzailea</th>
          </tr>

          <tr
            v-for="(item, index) in itzulpenak"
            :key="index"
            :class="{ closed: !item.acf.itxita }"
          >
            <td>{{ item.date.slice(0, 10) }}</td>
            <td>
              <router-link :to="`/edit-post/${item.id}`">{{
                item.title.rendered
              }}</router-link>
              <font-awesome-icon icon="arrow-circle-right" />
            </td>
            <td>{{ item._embedded.author[0].name }}</td>
            <td>{{ item.acf.saila }}</td>
            <td>{{ item.acf.bidaltzailea }}</td>
          </tr>
        </table>
      </div>

      <div class="pagination">
        <template v-for="index in pageTotal" :key="index">
          <button @click="_load(index)" :class="{ active: index == page }">
            {{ index }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
// @ is an alias to /src
export default {
  name: "AllPosts",
  components: {
    Loader,
  },
  data() {
    return {
      loader: true,
      itzulpenak: [],
      page: null,
      pageTotal: null,
      perPage: 30,
    };
  },
  mounted() {
    document.title = "Itzulpen guztiak - Itzulpengintza zerbitzua";
    this._load(1);
  },
  methods: {
    _load(i) {
      this.page = i;
      axios
        .get("wp/v2/itzulpena?_embed&per_page=" + this.perPage + "&page=" + i)
        .then((res) => {
          this.itzulpenak = res.data;
          this.pageTotal = parseInt(res.headers["x-wp-totalpages"]);
          this.loader = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.posts {
  &.blur {
    filter: blur(5px);
  }
}
</style>
